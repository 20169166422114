body {
    font-family: Arial;
    margin: auto;
    width: 100%;
    background-color: #b3ffbb;
    min-width: 400px;
}


.content {
    text-align: center;
    margin: auto;
    width: 90%;
}

.pwBox {
    text-align: center;
    width: 100%;
    height: 23px;
    resize: none;
    background-color: #f7fff8;
    border: none;
}

.txtBoxes {
    width: 100%;
    resize: none;
    background-color: #f7fff8;
    border: none;
}

.btns {
    width: 100%;
    border: none;
}

.btnLeft {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #efefef;
    width: 50%;
    height: 50px;
    font-weight: bold;
    border: none;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}

.btnRight {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #efefef;
    width: 50%;
    height: 50px;
    font-weight: bold;
    border: none;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}

.btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #efefef;
    width: 50%;
    height: 50px;
    font-weight: bold;
    border: none;
    border-radius: 25px;
}

    .btn:hover {
        background-color: #cccccc;
    }

.btnLeft:hover {
    background-color: #cccccc;
}

.btnRight:hover {
    background-color: #cccccc;
}
